import { LogEntry } from "../model";
import React, { useCallback, useState } from "react";
import { Button, MenuItem, Stack, TextField } from "@mui/material";

interface LogEntryState {
    startTime: string;
    duration: string;
    breast: LogEntry["breast"];
    isActive: boolean;
}

const initialState: LogEntryState = {
    startTime: "",
    duration: "",
    breast: "left",
    isActive: false,
};

function computeStartTime(date: Date, startTime: string) {
    const [hours, minutes] = startTime.split(":");
    const logEntryStart = new Date(date.getTime());
    logEntryStart.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    if (logEntryStart.getHours() < 6) {
        logEntryStart.setDate(logEntryStart.getDate() + 1);
    }

    return logEntryStart.getTime();
}

export const AddLogEntryForm = ({
    addEntry,
    date,
}: {
    addEntry: (logEntry: LogEntry) => void;
    date: Date;
}) => {
    const addValidationToStartTimeInput = useCallback(
        (element: HTMLInputElement) => {
            if (element) {
                element.pattern = "\\d\\d:\\d\\d";
            }
        },
        []
    );

    const [{ startTime, duration, breast, isActive }, setState] =
        useState(initialState);

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();

                const logEntryStart = computeStartTime(date, startTime);
                addEntry({
                    breast,
                    startTime: logEntryStart,
                    endTime: logEntryStart + parseInt(duration, 10) * 1000 * 60,
                });

                setState(initialState);
            }}
        >
            <Stack spacing={2}>
                <TextField
                    select
                    label="Bryst"
                    required
                    value={breast}
                    onChange={(event) =>
                        setState((prev) => ({
                            ...prev,
                            breast: event.target.value as LogEntry["breast"],
                        }))
                    }
                >
                    <MenuItem value="left">Venstre</MenuItem>
                    <MenuItem value="right">Høyre</MenuItem>
                </TextField>
                <TextField
                    required
                    inputRef={addValidationToStartTimeInput}
                    label="Starttid"
                    type="text"
                    placeholder="08:15"
                    value={startTime}
                    onChange={(event) =>
                        setState((prev) => ({
                            ...prev,
                            startTime: event.target.value,
                        }))
                    }
                />
                <TextField
                    required
                    label="Minutter"
                    type="number"
                    value={duration}
                    onChange={(event) =>
                        setState((prev) => ({
                            ...prev,
                            duration: event.target.value,
                        }))
                    }
                />
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" type="submit">
                        Legg til
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
