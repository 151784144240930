import { useEffect } from "react";
import { State } from "./model";

export function useShareListener(state: State) {
    useEffect(() => {
        let tapCount = 0;
        let prevTap = 0;
        const eventListener = () => {
            if (Date.now() - prevTap > 300) {
                tapCount = 0;
            }
            ++tapCount;
            prevTap = Date.now();
            if (tapCount === 5) {
                tapCount = 0;
                const text = JSON.stringify(state, null, 2);
                if (navigator && navigator.share) {
                    navigator.share({ text: text });
                } else {
                    console.log(text);
                }
            }
        };
        window.addEventListener("mousedown", eventListener);
        return () => window.removeEventListener("mousedown", eventListener);
    }, [state]);
}
