import React, { useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Icon,
    IconButton,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import {
    formatDay,
    formatTime,
    groupBySession,
    toSessionTotal,
    toText,
} from "../util";
import { AddLogEntryForm } from "./AddLogEntryForm";
import { LogEntry } from "../model";
import { Session } from "./Session";
import { DeleteButton } from "./DeleteButton";

interface Props {
    day: string;
    logEntries: LogEntry[];
    deleteEntry: (startTime: number) => void;
    addEntry: (logEntry: LogEntry) => void;
}

export function DayCard({ day, logEntries, addEntry, deleteEntry }: Props) {
    const [edit, setEdit] = useState(false);

    const left = logEntries
        .filter((it) => it.breast === "left")
        .map((it) => it.endTime - it.startTime)
        .reduce((acc, cur) => acc + cur, 0);
    const right = logEntries
        .filter((it) => it.breast === "right")
        .map((it) => it.endTime - it.startTime)
        .reduce((acc, cur) => acc + cur, 0);
    const total = left + right;
    const sessions = groupBySession(logEntries).reverse();
    const lastSession = sessions[0] ?? [];

    return (
        <Card>
            <CardHeader
                title={formatDay(new Date(day))}
                subheader={`Ammet i ${formatTime(total)} fordelt på ${
                    sessions.length
                } stell`}
                action={
                    <IconButton onClick={() => setEdit((prev) => !prev)}>
                        <Icon>{edit ? "expand_less" : "expand_more"}</Icon>
                    </IconButton>
                }
            ></CardHeader>
            <Stack
                direction="row"
                color="primary.contrastText"
                bgcolor="primary.light"
                paddingTop={2}
                paddingBottom={2}
            >
                <Stack width="50%" alignItems="center">
                    <Typography>Venstre</Typography>
                    <Typography variant="h4" component="div">
                        {formatTime(left)}
                    </Typography>
                </Stack>
                <Stack width="50%" alignItems="center">
                    <Typography>Høyre</Typography>
                    <Typography variant="h4" component="div">
                        {formatTime(right)}
                    </Typography>
                </Stack>
            </Stack>
            {!edit && (
                <Stack padding={2}>
                    <Session session={lastSession} />
                </Stack>
            )}
            {edit && (
                <CardContent>
                    <Stack spacing={2}>
                        {sessions.map((session, i) => (
                            <Box key={i}>
                                {session.reverse().map((logEntry, i) => (
                                    <ListItem
                                        disableGutters
                                        key={logEntry.startTime}
                                        secondaryAction={
                                            <DeleteButton
                                                logEntry={logEntry}
                                                deleteEntry={deleteEntry}
                                            />
                                        }
                                    >
                                        <ListItemText>
                                            {toText(logEntry)}
                                        </ListItemText>
                                    </ListItem>
                                ))}
                                <ListItem
                                    sx={{
                                        height: "48px",
                                        borderTop: 1,
                                        borderColor: "secondary.main",
                                        color: "secondary.main",
                                    }}
                                    disableGutters
                                    color="secondary.main"
                                >{`${toSessionTotal(session)}`}</ListItem>
                            </Box>
                        ))}
                    </Stack>
                </CardContent>
            )}
            {edit && (
                <>
                    <Divider></Divider>
                    <Box padding={2} paddingTop={4} paddingBottom={4}>
                        <AddLogEntryForm
                            addEntry={addEntry}
                            date={new Date(day)}
                        />
                    </Box>
                </>
            )}
        </Card>
    );
}
