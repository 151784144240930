import { Box, Button, Stack } from "@mui/material";
import { Current } from "./Current";
import React from "react";
import { PartialLogEntry } from "../model";

export function Timer({
    current,
    stop,
    startLeft,
    startRight,
}: {
    current?: PartialLogEntry;
    stop: () => void;
    startLeft: () => void;
    startRight: () => void;
}) {
    return (
        <Box mb={2} mt={2}>
            {current ? (
                <Stack spacing={2}>
                    <Current current={current} />
                    <Button
                        fullWidth={false}
                        variant="contained"
                        onClick={stop}
                        color="error"
                    >
                        Stopp
                    </Button>
                </Stack>
            ) : (
                <Stack spacing={2} direction="row">
                    <Button
                        fullWidth={true}
                        variant="contained"
                        onClick={startLeft}
                    >
                        Venstre
                    </Button>

                    <Button
                        fullWidth={true}
                        variant="contained"
                        onClick={startRight}
                    >
                        Høyre
                    </Button>
                </Stack>
            )}
        </Box>
    );
}
