import React, { ReactElement } from "react";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
} from "@mui/material";
import { PaymentFlowState } from "./usePaymentFlow";

interface Props {
    state: PaymentFlowState;
}

export function PaymentFlow({ state }: Props): ReactElement {
    switch (state.status) {
        case "PENDING":
            return <CircularProgress />;
        case "SUCCESS":
            return <Alert severity="success">Takk!</Alert>;
        case "ERROR":
            return (
                <Alert severity="error">
                    Noe gikk galt med betalingen, vi prøver igjen senere.
                </Alert>
            );
        case "READY":
            return (
                <Card>
                    <CardHeader
                        title="Prøveperioden er utløpt"
                        subheader="Du må kjøpe Ammetid før du kan legge inn flere stell."
                    ></CardHeader>
                    <CardContent sx={{}}>
                        <Typography variant="body1">Pris</Typography>
                        <Typography variant="h4" component="div">
                            Kr 49,-
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            fullWidth={true}
                            onClick={
                                state.status === "READY"
                                    ? state.requestPayment
                                    : () => {}
                            }
                        >
                            Aktiver ubegrenset bruk
                        </Button>
                    </CardActions>
                </Card>
            );
    }
}
