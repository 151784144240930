import React, { useCallback } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { History } from "./history/History";
import { usePersistentState } from "./usePersistentState";
import { useWakeLock } from "./useWakeLock";
import { LogEntry } from "./model";
import { Timer } from "./timer/Timer";
import { PaymentFlow } from "./payment/PaymentFlow";
import { useProduct } from "./payment/useProduct";
import { usePaymentFlow } from "./payment/usePaymentFlow";
import { useShareListener } from "./useShareListener";

const PRODUCT_ID = "basic";

function App() {
    const [state, setState] = usePersistentState();
    const product = useProduct(PRODUCT_ID);
    const paymentFlow = usePaymentFlow(PRODUCT_ID);

    useWakeLock();
    useShareListener(state);

    const start = async (breast: "left" | "right") => {
        setState((prev) => ({
            savedAt: state.savedAt,
            current: { startTime: Date.now(), breast },
            history: state.current
                ? [
                      ...prev.history,
                      {
                          ...state.current,
                          endTime: Date.now(),
                      },
                  ]
                : prev.history,
        }));
    };

    const startLeft = () => start("left");

    const startRight = () => start("right");

    const stop = () => {
        setState((prev) => ({
            ...prev,
            current: undefined,
            history: prev.current
                ? [
                      ...prev.history,
                      {
                          ...prev.current,
                          endTime: Date.now(),
                      },
                  ]
                : prev.history,
        }));
    };

    const addEntry = (logEntry: LogEntry) => {
        setState((prev) => ({
            ...prev,
            history: [...prev.history, logEntry].sort(
                (a, b) => a.startTime - b.startTime
            ),
        }));
    };

    const deleteEntry = useCallback(
        (startTime: number) => {
            setState((prev) => ({
                ...prev,
                history: prev.history.filter(
                    (logEntry) => logEntry.startTime !== startTime
                ),
            }));
        },
        [setState]
    );

    const showTimer =
        product.status === "PURCHASED" ||
        product.status === "NOT_AVAILABLE" ||
        paymentFlow.status === "ERROR" ||
        paymentFlow.status === "SUCCESS";

    return (
        <div className="App">
            <Grid container spacing={2} padding={2}>
                <Grid item xs={12}>
                    {product.status === "PENDING" && <CircularProgress />}
                    {product.status === "NOT_PURCHASED" && (
                        <PaymentFlow state={paymentFlow} />
                    )}
                    {showTimer && (
                        <Timer
                            current={state.current}
                            stop={stop}
                            startLeft={startLeft}
                            startRight={startRight}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <History
                        logEntries={state.history}
                        deleteEntry={deleteEntry}
                        addEntry={addEntry}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="text.secondary" textAlign="center">
                        Versjon 10
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default App;
