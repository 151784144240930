import { LogEntry } from "./model";

export function formatDate(timestamp: number) {
    const date = new Date(timestamp);
    return date.toLocaleTimeString(undefined, {
        timeStyle: "short",
    });
}

export function formatTime(timeMs: number, includeSeconds: boolean = false) {
    const time = Math.floor(timeMs / 1000);
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = time - hours * 3600 - minutes * 60;

    const parts = [];
    if (hours > 0) {
        parts.push(`${hours} t`);
    }
    if (minutes > 0) {
        parts.push(`${minutes} m`);
    }
    if ((includeSeconds && seconds > 0) || parts.length === 0) {
        parts.push(`${seconds} s`);
    }

    return parts.join(" ");
}

export function groupByDay(entries: LogEntry[]) {
    return entries.reduce<{ [k: string]: LogEntry[] }>((acc, obj) => {
        const day = toDateString(obj.startTime);
        if (!acc.hasOwnProperty(day)) {
            acc[day] = [];
        }
        acc[day].push(obj);
        return acc;
    }, {});
}

export function toDateString(timestamp: number) {
    const date = new Date(timestamp);
    if (date.getHours() < 6) {
        date.setDate(date.getDate() - 1);
    }

    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
}

export function toText(logEntry: LogEntry) {
    return ` ${logEntry.breast === "left" ? "V" : "H"} ${formatDate(
        logEntry.startTime
    )} — ${formatTime(logEntry.endTime - logEntry.startTime)} `;
}

export function formatDay(date: Date) {
    const suffix = `${date.getDate()}/${date.getMonth() + 1}`;
    switch (date.getDay()) {
        case 0:
            return `Søndag ${suffix}`;
        case 1:
            return `Mandag ${suffix}`;
        case 2:
            return `Tirsdag ${suffix}`;
        case 3:
            return `Onsdag ${suffix}`;
        case 4:
            return `Torsdag ${suffix}`;
        case 5:
            return `Fredag ${suffix}`;
        case 6:
            return `Lørdag ${suffix}`;
    }
}

export function toTotalTime(logEntries: LogEntry[]) {
    if (logEntries.length === 0) {
        return formatTime(0);
    }
    return `${formatTime(
        logEntries
            .map((logEntry) => logEntry.endTime - logEntry.startTime)
            .reduce((acc, cur) => acc + cur)
    )}`;
}

const HALF_HOUR = 1000 * 60 * 30;

export function groupBySession(logEntries: LogEntry[]) {
    const sessions: LogEntry[][] = [];
    for (const logEntry of logEntries) {
        if (sessions.length === 0) {
            sessions.push([logEntry]);
        } else {
            const currentSession = sessions[sessions.length - 1];
            const lastEntry = currentSession[currentSession.length - 1];
            if (logEntry.startTime - lastEntry.endTime < HALF_HOUR) {
                sessions[sessions.length - 1].push(logEntry);
            } else {
                sessions.push([logEntry]);
            }
        }
    }
    return sessions;
}

export function toSessionTotal(session: LogEntry[]) {
    const parts: string[] = [];
    const left = session.filter((it) => it.breast === "left");
    const right = session.filter((it) => it.breast === "right");

    if (left.length > 0) {
        parts.push(`V ${toTotalTime(left)}`);
    }

    if (right.length > 0) {
        parts.push(`H ${toTotalTime(right)}`);
    }

    return parts.length === 2
        ? `${parts.join(" + ")} = ${toTotalTime(session)}`
        : toTotalTime(session);
}
