import React, { ReactElement, useCallback, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
} from "@mui/material";
import { LogEntry } from "../model";
import { toText } from "../util";

interface Props {
    logEntry: LogEntry;
    deleteEntry: (startTime: number) => void;
}

export function DeleteButton({ logEntry, deleteEntry }: Props): ReactElement {
    const [isOpen, setIsOpen] = useState(false);
    const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
    const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
    const onDelete = useCallback(
        () => deleteEntry(logEntry.startTime),
        [logEntry.startTime, deleteEntry]
    );

    return (
        <>
            <IconButton onClick={onOpen}>
                <Icon>delete</Icon>
            </IconButton>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>Slett oppføring?</DialogTitle>
                <DialogContent>{toText(logEntry)}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Nei</Button>
                    <Button onClick={onDelete}>Ja</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
