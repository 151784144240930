import { LogEntry } from "../model";
import { Box, Stack } from "@mui/material";
import { toSessionTotal, toText } from "../util";
import React from "react";

export const Session = ({ session }: { session: LogEntry[] }) => (
    <Stack spacing={1}>
        {session.reverse().map((logEntry, i) => (
            <div key={logEntry.startTime}>{toText(logEntry)}</div>
        ))}
        <Box color="secondary.main" borderTop={1} paddingTop={1}>{`${toSessionTotal(
            session
        )}`}</Box>
    </Stack>
);
