import { State } from "./model";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { loadState, saveState } from "./storage";

export function usePersistentState(): [State, Dispatch<SetStateAction<State>>] {
    const [state, setState] = useState<State>(loadState);

    useEffect(() => {
        saveState(state);

        const onVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                const savedState = loadState();
                if (
                    savedState.savedAt &&
                    (!state.savedAt || savedState.savedAt > state.savedAt)
                ) {
                    setState(savedState);
                }
            }
        };

        document.addEventListener("visibilitychange", onVisibilityChange);
        return () =>
            document.removeEventListener(
                "visibilitychange",
                onVisibilityChange
            );
    }, [state]);

    return [state, setState];
}
