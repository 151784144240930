import React, { useEffect, useState } from "react";
import { PartialLogEntry } from "../model";
import { formatTime } from "../util";
import { Box, Typography } from "@mui/material";

export const Current = ({ current }: { current: PartialLogEntry }) => {
    const [seconds, setSeconds] = useState(
        current ? Date.now() - current.startTime : 0
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(current ? Date.now() - current.startTime : 0);
        }, 300);
        return () => {
            clearInterval(interval);
        };
    }, [current]);

    const text = current.breast === "left" ? "Venstre" : "Høyre";
    const time = formatTime(seconds, true);

    return (
        <Box>
            <Typography
                textAlign="center"
                variant="body1"
                component="div"
                color="text.secondary"
            >
                {text}
            </Typography>
            <Typography textAlign="center" variant="h2">
                {time}
            </Typography>
        </Box>
    );
};
