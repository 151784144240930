import { State } from "./model";

export const loadState = (): State =>
    JSON.parse(localStorage.getItem("amme") ?? JSON.stringify({ history: [] }));

export const saveState = (state: State) =>
    localStorage.setItem(
        "amme",
        JSON.stringify({ ...state, savedAt: Date.now() })
    );

const version = localStorage.getItem("amme.version");

if (version && version !== "4") {
    const state = loadState();
    state.history.push({
        breast: "left",
        startTime: new Date("2022-05-01 10:21:00").getTime(),
        endTime: new Date("2022-05-01 10:36:00").getTime(),
    });
    state.history.sort((a, b) => a.startTime - b.startTime);
    localStorage.setItem("amme.version", "4");
    localStorage.setItem("amme", JSON.stringify(state));
}
