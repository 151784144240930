import { useState } from "react";

export type PaymentFlowState =
    | {
          status: "READY";
          requestPayment(): void;
      }
    | { status: "PENDING" | "SUCCESS" | "ERROR" };

export function usePaymentFlow(itemId: string): PaymentFlowState {
    const [status, setStatus] = useState<PaymentFlowState["status"]>("READY");

    if (status === "READY") {
        return {
            status,
            async requestPayment() {
                setStatus("PENDING");
                try {
                    await requestPayment(itemId);
                    setStatus("SUCCESS");
                } catch (e) {
                    setStatus("ERROR");
                }
            },
        };
    } else {
        return { status };
    }
}

async function requestPayment(itemId: string) {
    const request = new PaymentRequest(
        [
            {
                supportedMethods: "https://play.google.com/billing",
                data: {
                    sku: itemId,
                },
            },
        ],
        {} as PaymentDetailsInit // this info is not required for Google Play
    );

    const {
        details: { purchaseToken },
        complete,
    } = await request.show();

    await complete(await verifyPurchase(purchaseToken));
}

async function verifyPurchase(
    purchaseToken: string
): Promise<"success" | "fail"> {
    // TODO verify payment
    return "success";
}
