import { useEffect, useState } from "react";

export type ProductState = {
    itemId: string;
    status: "PENDING" | "NOT_AVAILABLE" | "PURCHASED" | "NOT_PURCHASED";
};

export function useProduct(itemId: string): ProductState {
    const [state, setState] = useState<ProductState>({
        itemId,
        status: "PENDING",
    });

    useEffect(() => {
        setState({ itemId, status: "PENDING" });
        (async () => {
            try {
                setState(await getProductState(itemId));
            } catch (e) {
                setState({ itemId, status: "NOT_AVAILABLE" });
            }
        })();
    }, [itemId]);

    return state;
}

async function getProductState(itemId: string): Promise<ProductState> {
    localStorage.setItem("isPurchased", "true"); // TODO remove this
    const isPurchased = localStorage.getItem("isPurchased") === "true";
    if (isPurchased) {
        return {
            itemId,
            status: "PURCHASED",
        };
    }

    const digitalGoodsService = await getDigitalGoodsService();
    if (!digitalGoodsService) {
        return {
            itemId,
            status: "NOT_AVAILABLE",
        };
    }

    const purchases = await digitalGoodsService.listPurchases();
    if (purchases.some((purchase) => purchase.itemId === itemId)) {
        return {
            itemId,
            status: "PURCHASED",
        };
    } else {
        return {
            itemId,
            status: "NOT_PURCHASED",
        };
    }
}

async function getDigitalGoodsService(): Promise<
    DigitalGoodsService | undefined
> {
    if (window?.getDigitalGoodsService) {
        return await window.getDigitalGoodsService(
            "https://play.google.com/billing"
        );
    } else {
        return undefined;
    }
}
