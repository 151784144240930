import { useEffect } from "react";

export function useWakeLock() {
    useEffect(() => {
        if (!navigator.wakeLock) {
            return;
        }

        let lock: WakeLockSentinel | undefined;

        const requestWakeLock = () => {
            navigator.wakeLock.request("screen").then((it) => {
                lock = it;
                console.log("Lock acquired");
            });
        };

        const releaseWakeLock = () => {
            lock?.release();
            lock = undefined;
            console.log("Lock released");
        };

        const onVisibilityChange = () => {
            if (document.visibilityState === "hidden") {
                releaseWakeLock();
            } else {
                requestWakeLock();
            }
        };

        requestWakeLock();

        document.addEventListener("visibilitychange", onVisibilityChange);
        return () =>
            document.removeEventListener(
                "visibilitychange",
                onVisibilityChange
            );
    }, []);
}
