import React, { useCallback, useState } from "react";
import { LogEntry } from "../model";
import { Button, Stack } from "@mui/material";
import { groupByDay } from "../util";
import { DayCard } from "./DayCard";

const PAGE_SIZE = 7;

export const History = ({
    logEntries,
    deleteEntry,
    addEntry,
}: {
    logEntries: LogEntry[];
    deleteEntry: (startTime: number) => void;
    addEntry: (logEntry: LogEntry) => void;
}) => {
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const increasePageSize = useCallback(
        () => setPageSize((prev) => prev + PAGE_SIZE),
        []
    );
    const history = groupByDay(logEntries);
    const days = Object.keys(history).sort().reverse();
    const daysOnCurrentPage = days.slice(0, pageSize);

    return (
        <Stack spacing={2}>
            {daysOnCurrentPage.map((day) => (
                <DayCard
                    key={day}
                    day={day}
                    logEntries={history[day]}
                    deleteEntry={deleteEntry}
                    addEntry={addEntry}
                />
            ))}
            {daysOnCurrentPage.length < days.length && (
                <Button onClick={increasePageSize}>Vis flere</Button>
            )}
        </Stack>
    );
};
